import React from 'react'
// import logo from './logo.svg';
import image from '../../image.png'

const Logo = () => {
  return (
    <div>
      <img src={image} className='App-logo' alt="logo" />
    </div>
  )
}

export default Logo